@value colors: '../themes/Colors.css';
@value primary, secondary, third, lines, icons, mainBg, snow from colors;

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 3px;
  background-color: primary;
  width: 0%;
  opacity: 0;
  transition: opacity 500ms linear;
}

.loading.loadingActive {
  opacity: 1;
  width: 100%;
  transition: width 2000ms linear;
}

.menu {
  display: none;
}

/* .authMenu {
  display: none;
} */

.authMenu {
  position: relative;
  z-index: 30;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.authMenu a {
  cursor: pointer;
  color: #999;
  text-align: center;
  text-decoration: none;
}
.authMenu a:hover {
  opacity: 0.5;
}

.authMenu .signIn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: primary;
  border: 2px solid primary;
  border-radius: 5px;
  padding: 5px 0px;
  min-width: 120px;
  white-space: nowrap;
  margin-left: 40px;
}

.authMenu .signedInCont:hover {
  background-color: rgba(0, 0, 0, 0);
}

.authMenu .register {
  color: primary;
  white-space: nowrap;
}

.authMenu .current {
  border-bottom: 2px solid primary;
  padding-top: 2px;
}

.authMenu .currentSignIn {
  font-weight: 600;
}

.userMenu {
  position: absolute;
  top: 46px;
  display: flex;
  z-index: 100;
  right: -20px;
  min-width: 150px;
  background-color: white;
  flex-direction: column;
  align-items: flex-end;

  white-space: nowrap;
  box-shadow: 0px 0px 5px #e2e2e2;
  border-radius: 6px;
  overflow: hidden;
}

.userMenu a {
  text-align: left;
  padding: 15px 25px 15px 15px;
  border-bottom: 1px solid lines;
  margin: 0;
  width: 100%;
}

.userMenu a:last-child {
  text-align: left;
  padding: 15px 25px 15px 15px;
  border-bottom: 0px;
  margin: 0;
  width: 100%;
}

.userMenu a:hover {
  background-color: #f8f8f8;
  opacity: 1;
}
.userMenu a:hover .icon {
  color: icons;
  font-weight: normal;
}

.userMenuItem {
  display: flex;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
}

.icon {
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.menuBg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9;
}

@media only screen and (min-width: 768px) {
  .menu {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
  }

  .menu a {
    color: #888;
    text-align: center;
    margin: 0 20px;
    text-decoration: none;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    border: 0;
  }

  .menu a:hover {
    color: #888;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0);
  }

  .menu .current {
    border-bottom: 2px solid primary;
    padding-top: 2px;
  }

  .friends {
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
}
